var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-1"
  }, [_c('b-card-code', {
    attrs: {
      "title": "Accordion"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAccordionDefault) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Turn a group of ")]), _c('code', [_vm._v("<app-collapse>")]), _c('span', [_vm._v(" components into an accordion by supplying an accordion group identifier via the ")]), _c('code', [_vm._v("accordion")]), _c('span', [_vm._v(" prop. Note that only one collapse in an accordion group can be open at a time.")])]), _c('app-collapse', {
    attrs: {
      "accordion": ""
    }
  }, [_c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 1"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")]), _c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 2"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")]), _c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 3"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")]), _c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 4"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }