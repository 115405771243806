var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Types"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCollapseTypes) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat dolores nostrum modi, dolor, neque distinctio vel ut itaque numquam quos magni dolore ea earum tempore est excepturi perspiciatis, placeat odio. ")]), _c('b-form-group', [_c('b-form-radio-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "id": "radio-group-2",
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.collapseType,
      callback: function callback($$v) {
        _vm.collapseType = $$v;
      },
      expression: "collapseType"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "default"
    }
  }, [_vm._v(" Default ")]), _c('b-form-radio', {
    attrs: {
      "value": "shadow"
    }
  }, [_vm._v(" Shadow ")]), _c('b-form-radio', {
    attrs: {
      "value": "margin"
    }
  }, [_vm._v(" Margin ")]), _c('b-form-radio', {
    attrs: {
      "value": "border"
    }
  }, [_vm._v(" Border ")])], 1)], 1), _c('app-collapse', {
    attrs: {
      "accordion": "",
      "type": _vm.collapseType
    }
  }, [_c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 1"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")]), _c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 2"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")]), _c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 3"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")]), _c('app-collapse-item', {
    attrs: {
      "title": "Accordion Item 4"
    }
  }, [_vm._v(" Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }